<template>
  <div class="ecommerce-searchbar">
    <div
      class="d-flex justify-content-center align-items-center searchbar-icon"
    >
      <feather-icon icon="SearchIcon" class="search-icon" size="21" />
    </div>
    <input
      :data-testid="dataTestId"
      type="text"
      class="search-input"
      placeholder="Buscar..."
      v-model="searchQuery"
    />
    <div
      class="d-flex justify-content-center align-items-center searchbar-icon"
    >
      <feather-icon
        icon="XIcon"
        class="reset-icon"
        size="21"
        v-if="searchQuery"
        @click="resetSearch()"
      />
    </div>
  </div>
</template>

<script>
import _ from "underscore"

export default {
  props: {
    dataTestId: {
      type: String,
      default: "searchbar-input",
    },
  },
  data() {
    return {
      searchQuery: "",
    }
  },
  watch: {
    searchQuery: {
      handler: _.debounce(function (val) {
        this.$emit("onChange", val)
      }, 400),
    },
  },
  methods: {
    resetSearch() {
      this.searchQuery = ""
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
.search-input {
  padding: 1rem;
  min-width: 400px;
  background-color: transparent;
  border: none;
  color: $body-color;
  &:focus {
    outline: none;
  }

  @include media-breakpoint-down(md) {
    min-width: 200px;
  }
}

.reset-icon {
  cursor: pointer;
}

@keyframes adjust-searchbar {
  to {
    padding: 0.5rem;
  }
}

.searchbar-icon,
.search-input {
  animation: adjust-searchbar linear both;
  animation-timeline: scroll();
  animation-range: entry 50% cover 80%;
}

.searchbar-icon {
  padding: 1rem;
}

.ecommerce-searchbar {
  display: grid;
  border-radius: 2rem;
  font-weight: 500;
  grid-template-columns: auto 1fr auto;
  border: solid 1px rgba(#3b4253, 1);
}

.dark-layout {
  .ecommerce-searchbar {
    background-color: rgba(#161d31, 1);
    color: rgba(white, 0.8);
  }
}
</style>

<template>
  <b-card
    v-if="product && selectedStoreProduct"
    class="product__card mb-0"
    no-body
    text-variant="primary"
  >
    <div class="product__image__container">
      <b-img
        :alt="`${product.name}-${product.id}`"
        fluid
        rounded
        :src="`${product.logo}`"
        class="product__image"
      />
    </div>

    <div class="product__details">
      <b-card-text class="bold mb-0 pb-0 h5">
        {{ product.name }}
      </b-card-text>

      <p v-if="product.variant" class="text-secondary line-clamp-2">
        {{ product.variant }}
      </p>
      <div v-if="selectedStoreProduct" class="item-wrapper mt-1">
        <div class="item-cost">
          <h5 class="item-price text-body d-flex justify-content-between">
            <span
              class="mr-1 bold"
              v-if="rangePrice && product.min_price && product.max_price"
            >
              ${{ product.min_price }} - ${{ product.max_price }}
            </span>
            <span class="mr-1 bold" v-else>
              ${{ selectedStoreProduct.unit_price | money }}
            </span>
          </h5>
        </div>
      </div>

      <div v-if="bestPromotion" class="pt-1">
        <b-badge
          v-if="bestPromotion.promo_type !== 'free_product'"
          pill
          :variant="'light-info'"
          class="text-break text-wrap"
        >
          <span>
            {{ "Compra " + bestPromotion.needs_to_buy + " y gana" }}
          </span>
          <span v-if="bestPromotion.promo_type === 'price_discount'">
            {{ bestPromotion.discount + "% de descuento" }}
          </span>
          <span v-else-if="bestPromotion.promo_type === 'reward_points'">
            {{ bestPromotion.reward_points + "% en puntos" }}
          </span>
        </b-badge>

        <b-badge
          v-if="bestPromotion.promo_type === 'free_product'"
          pill
          :variant="'light-info'"
        >
          {{ bestPromotion.needs_to_buy }} {{ "x" }}
          {{ bestPromotion.gets_you }}
        </b-badge>
      </div>

      <div
        v-if="product.store_products && product.store_products.length > 1"
        class="mt-auto"
      >
        <span> * Elige una opción: </span>
        <b-form-select
          v-model="selectedVariant"
          @change="handleStoreProductChange"
          @click.native.stop
        >
          <b-form-select-option
            v-for="(option, index) in storeProductOptions"
            :key="index"
            :value="option.value"
          >
            {{ option.text }}
          </b-form-select-option>
        </b-form-select>
      </div>

      <div class="d-flex justify-end mt-1 w-100">
        <b-button
          :variant="!validInventory || isDisabled ? 'secondary' : 'success'"
          class="btn__cart__small ml-auto"
          @click.stop="handleAddToCart"
          :disabled="!validInventory || isDisabled"
        >
          <i class="fa fa-lg fa-cart-plus" />
        </b-button>
      </div>
    </div>

    <div class="product__buttons">
      <b-button
        :variant="!validInventory || isDisabled ? 'secondary' : 'success'"
        class="btn__cart"
        @click.stop="handleAddToCart"
        size="sm"
        v-ripple
        :disabled="!validInventory || isDisabled"
      >
        <div v-if="validInventory && !isDisabled">
          <feather-icon icon="ShoppingCartIcon" class="mr-50" />
          <span class="btn__text">Agregar</span>
        </div>
        <div v-else>
          <span class="btn__text">{{ disabledText }}</span>
        </div>
      </b-button>
    </div>
  </b-card>
</template>

<script>
import vSelect from "vue-select"
import { mapGetters } from "vuex"

// Utils
import { getBestPromotion } from "@/@core/utils/store-product-utils"

export default {
  components: {
    vSelect,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    onAddToCart: {
      type: Function,
      required: true,
    },
    validateInventory: {
      type: Boolean,
      default: true,
      required: false,
    },
    rangePrice: {
      type: Boolean,
      default: false,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabledText: {
      type: String,
      default: "No disponible",
      required: false,
    },
  },
  data() {
    return {
      selectedVariant: this.product.store_products[0].id,
      selectedStoreProduct: null,
    }
  },
  mounted() {
    this.initializeSelectedStoreProduct()
  },
  methods: {
    handleStoreProductChange() {
      this.selectedStoreProduct = {
        ...this.product.store_products.find(
          (storeProduct) => storeProduct.id === this.selectedVariant
        ),
        units: 1,
      }
    },

    handleAddToCart() {
      this.onAddToCart({
        product: this.product,
        store_product: this.selectedStoreProduct,
      })
    },

    initializeSelectedStoreProduct() {
      this.selectedStoreProduct = {
        ...this.product.store_products[0],
        units: 1,
      }
    },
  },
  computed: {
    ...mapGetters("stores", ["storeReferences"]),
    bestPromotion() {
      if (
        !this.selectedStoreProduct ||
        !this.selectedStoreProduct.promotion_attributes
      )
        return

      return getBestPromotion(this.selectedStoreProduct)
    },

    validInventory() {
      if (this.validateInventory) {
        return Number(this.selectedStoreProduct.inventory) === 0
      } else {
        return true
      }
    },

    storeProductOptions() {
      if (
        !this.product.store_products ||
        this.product.store_products.length < 2
      ) {
        return
      }

      return (
        this.product?.store_products?.map((store_product) => {
          const variantNames =
            store_product.store_product_variant_attributes
              ?.map((variant) => variant.variant_option_attributes?.option_name || "")
              .join(" ") || `$${store_product.unit_price}`
          return {
            value: store_product.id,
            text: variantNames,
          }
        }) || []
      )
    },
  },
}
</script>
